.barcode-container {
  position: relative;
  width: 100%;
  padding-top: 100%;

  & svg {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
  }
}
