.p-component-overlay {
    border-radius: 5px;
}

.p-inline-message .p-inline-message-icon {
    font-size: large;
}

.p-inline-message .p-inline-message-text {
    font-size: large;
}