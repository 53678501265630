
.login-body {
  display: flex;
  box-sizing: border-box;
  min-height: 100vh;
  background: linear-gradient(180deg, #2e323f 0%, #0a061a 100%);
  .login-image {
    height: 100vh;
    img {
      height: 100vh;
    }
  }
  .p-inputtext {
    color: #ffffff;
    background: rgba(255, 255, 255, 0.05);
  }
  label {
    color: #ffffff;
  }
  .login-panel {
    background-image: url('../../assets/images/effect-ondark.png');
    background-repeat: no-repeat;
    text-align: center;
    width: 100%;
    padding: 260px 100px 75px 100px;
    box-sizing: border-box;
    .login-appname {
      width: 250px;
    }
    .form-container {
      input {
        display: block;
        max-width: 320px;
        min-width: 270px;
        margin-bottom: 10px;
      }
    }
    .button-container {
      button {
        display: block;
        max-width: 320px;
        margin-bottom: 32px;
      }
      & > span {
        display: flex;
        color: #868c9b;
        margin-top: 1rem;
      }
    }
  }
}

@media (max-width: 991px) {
  .login-body .login-image {
    display: none;
  }
  .login-body .login-panel {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 60px 50px;
  }
}
