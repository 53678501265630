//Suggested location to add your overrides so that migration would be easy by just updating the SASS folder in the future
.status-ACTIVE {
    background: #66e056;
    color: #186800;
}

.status-PASSIVE {
    background: #e68770;
    color: #af240c;
}

.status-SUSPENDED {
    background: #e68770;
    color: #af240c;
}

.customer-badge{
    border-radius: var(--border-radius);
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
}

.loader{
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
