.card-header {
  background: #f8f9fa;
  color: #343a40;
  border: 1px solid #dee2e6;
  border-width: 1px 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 700;
}

.card-row {
  text-align: left;
  border: 1px solid #dee2e6;
  border-width: 0 0 1px 0;
  padding: 0.3rem 1rem;
  outline-color: #c7d2fe;
  word-break: break-word;
}

.files-table thead {
  display: none;
}
