.custom-listbox-container:not(:last-child) {
  margin-right: 1.5rem;
}

.custom-listbox-container .p-highlight {
  background: #c9c5f0 !important;
}

.custom-listbox-container .p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
  background: #e0ddf9 !important;
}
